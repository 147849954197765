<template>
    <div>
        <Slider />
        <Breadcrumbs v-if="category !== null"  :page="page" :items="breadcrumbs" color="white" />
        <section class="mb-10" v-if="post !== null">   
            <v-layout justify-space-around justify-center class="contact-layout">
                <v-flex xs9 sm9 offset-sm0 class="" align="" justify="" >
                        <v-card color="" tile flat>
                        <v-card-title primary-title class="">
                            <div class="">
                                <div class="zamu-sub-heading post-category">
                                    <span v-if="category !== null" class="capitalize">{{  category.category }},</span> 
                                    <span class="post-date ml-2">{{ formatDate(post.created_at , 'MMM DD, YYYY')}} </span>
                                </div>

                                <h3 class="headline zamu-heading mt-3">{{ post.title }}</h3>
                            </div>
                            </v-card-title>
                            <v-card-text>
                                <span class="blog-image" v-html="post.snippet"></span>
                            </v-card-text>
                          <v-card-text>
                              <v-row no-gutters>
                                    <v-col lg="10" md="10" sm="12" xs="12" cols="12" class="">
                                        <v-card color="" flat tile width="100%">
                                         <span class="" v-html="post.content"></span>
                                        </v-card>
                                    </v-col>
                              </v-row>
                          </v-card-text>
                        </v-card>
                </v-flex>
            </v-layout>
        </section>

    </div>
  
</template>

<script>
import coreMixin from '@/mixins/coreMixin';
import colorMixin from '@/mixins/colorMixin';

export default {
    name: 'Post',
     components: {
      Slider: () => import('./slider'),
      Breadcrumbs: () => import('../../layout/Breadcrumb.vue'),

     },
    mixins: [coreMixin, colorMixin],

     data () {
         return {
             page: 'FAQ-Post',
             post: null,
             category: null
         };
     },
     computed: {
        breadcrumbs() {
            const data = [
                {
                text: 'All FAQs',
                disabled: false,
                href: '/faqs',
                },
                {
                text: `${this.category.category}`,
                disabled: false,
                href: `/faqs/category/${this.category.id}`,
                },
                {
                text: `${this.post.title}`,
                disabled: true,
                href: ``,
                },
            ];
            return data;

        }
     },

     async mounted() {
         const id = this.$route.params.id;
        this.post = await this.retrieveFAQPost(id);
        this.category = await this.retrieveCategory(this.post.category);
    },

}
</script>

<style>

</style>